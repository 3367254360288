<template>
  <div v-if="!Object.values(isDataReady).every(v => v === true)" class="flex flex-col px-6 py-4">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
  <div v-else>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <label class="flex items-center">
        <span class="mr-2 text-sm font-semibold capitalize text-gray-200">{{ selectedAlertTag }}</span>
        <span v-if="required.alertType" class="text-xs text-red-400">Required</span>
      </label>
      <BaseRadio :options="alertTags" :key="alertTags" v-model="selectedAlertTag" />
      <div>
        <div v-if="selectedAlertTag === 'Coins'">
          <DropdownSelectCoin
            :starting-coins="preSelectedCoins"
            :display-count="8"
            v-model="alertData.coins"
            dropdown-width-class="w-full"
            button-width-class="w-full"
            @update="alertData.coins = $event"
            placeholder="Select Coin(s)"
            id="coin-dropdown"
          />
        </div>
        <div v-else-if="selectedAlertTag === 'Watchlist'">
          <BaseSelect
            dropdown-width-class="w-full"
            button-width-class="w-full"
            placeholder="Select Watchlist"
            :searchable="true"
            v-model="alertData.watchlist.id"
            :options="allWatchlists"
          />
        </div>
        <div v-else-if="selectedAlertTag === 'Category'">
          <BaseSelect
            button-width-class="w-full"
            dropdown-width-class="w-full"
            placeholder="Select Category"
            :searchable="true"
            v-model="alertData.category.id"
            :options="allCategories"
          />
        </div>
        <div v-else-if="selectedAlertTag === 'Subcategory'">
          <BaseSelect
            button-width-class="w-full"
            dropdown-width-class="w-full"
            placeholder="Select Subcategory"
            :searchable="true"
            v-model="alertData.subcategory.id"
            :options="allSubcategories"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <div class="flex items-center justify-between">
        <div class="text-sm font-semibold text-gray-200">Metrics</div>
        <BaseButton size="xs" type="secondary" @click="addTrigger"> + Add New Metric </BaseButton>
      </div>
      <div v-for="(trig, index) in alertData.metrics" :key="index" class="space-y-2 rounded-lg bg-gray-800/30 p-2">
        <div class="flex items-center">
          <BaseMenu
            :items="groupedMetricTags"
            @action="
              trig.mainMetric = $event;
              trig.id = null;
              trig.label = null;
              trig.name = null;
              trig.comparison = 'greater_than';
              trig.value = null;
            "
          >
            <span>{{ trig.mainMetric }}</span>
          </BaseMenu>
          <div class="ml-auto" v-if="alertData.metrics.length > 1">
            <BaseButton @press="removeTrigger(index)" type="ghost" size="xs">
              <TrashIcon class="h-4 w-4 text-red-500" />
            </BaseButton>
          </div>
        </div>
        <span v-if="trig.required" class="mt-0.5 px-6 text-xs text-red-400">Required</span>
        <div class="relative flex w-full items-center">
          <DropdownMetricTooltip v-if="trig.id" :id="trig.id" class="mr-1">
            <InformationCircleIcon
              class="h-4 w-4 cursor-pointer text-gray-700 hover:text-gray-300"
            ></InformationCircleIcon>
          </DropdownMetricTooltip>
          <div class="grid w-full grid-flow-col grid-cols-9 items-center space-x-1">
            <div class="col-span-4">
              <BaseSelect
                dropdown-width-class="w-full"
                button-width-class="w-full"
                placeholder="Select"
                v-model="trig.id"
                :searchable="true"
                :options="filteredEndpoints(trig.mainMetric, trig.id, trig)"
                @update:model-value="[trig.type, trig.name] = updateTrig(trig, $event)"
                id="metric-dropdown"
              />
            </div>

            <div class="col-span-1">
              <BaseSelect
                v-model="trig.comparison"
                dropdown-width-class="w-full"
                button-width-class="w-full"
                placeholder=">"
                :options="
                  allComparisons.map(r => {
                    return { id: r.id, label: r.text };
                  })
                "
                class="comparison-dropdown"
              />
            </div>

            <div class="col-span-4">
              <BaseNumberInput v-model="trig.value" :key="trig.id">
                <template v-if="getMetricSymbol(trig.id) == '$'" #left>$</template>
                <template v-if="getMetricSymbol(trig.id) == '%'" #right>%</template>
              </BaseNumberInput>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, nextTick } from 'vue';
import { titleize } from '@/composeables/filters';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import useHttp from '@/composeables/http';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import find from 'lodash/find';
import fromExponential from 'from-exponential';
import DropdownSelectCoin from '@/components/dropdown/DropdownSelectCoin.vue';
import DropdownMetricTooltip from '@/components/dropdown/DropdownMetricTooltip.vue';
import { useWatchlistStore } from '@/stores/watchlists';
import useEmitter from '@/composeables/emitter';

const emitter = useEmitter();
const store = useStore();
const $watchlistStore = useWatchlistStore();
const $http = useHttp();

const props = defineProps({
  alert: { type: Object, default: () => null }
});

//ALERT DATA
const alertData = ref(
  JSON.parse(window.localStorage.getItem(constructLocalStorageName('alertData'))) || {
    coins: [],
    watchlist: { id: null },
    category: { id: null },
    subcategory: { id: null },
    metrics: [
      {
        mainMetric: 'All',
        id: null,
        name: null,
        comparison: 'greater_than',
        value: null,
        type: null,
        required: false
      }
    ]
  }
);
watch(
  alertData,
  () => {
    handleAlertData();
  },
  { deep: true, immediate: true }
);
function constructLocalStorageName(s) {
  const path = 'alerts'
    .split('.')
    .map((s, i) => {
      return i == 0 ? s : s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join('');
  s = s.charAt(0).toUpperCase() + s.slice(1);
  return `${path}${s}`;
}
function handleAlertData() {
  let config = {};
  for (const [k, v] of Object.entries(alertData.value)) {
    if (['watchlist', 'category', 'subcategory'].includes(k)) {
      if (v && (v.uid != null || v.id != null)) {
        config[k] = v;
      }
    } else if ('coins' == k) {
      if (v && v.length > 0) {
        config[k] = v;
      }
    } else {
      config[k] = v;
    }
  }
  window.localStorage.setItem(constructLocalStorageName('alertData'), JSON.stringify(alertData.value));
  emitter.$emit('config:alert', { config: config });
}
function clearAlertData() {
  alertData.value = {
    coins: [],
    watchlist: { id: null },
    category: { id: null },
    subcategory: { id: null },
    metrics: [
      {
        mainMetric: 'All',
        id: null,
        name: null,
        comparison: 'greater_than',
        value: null,
        type: null,
        required: false
      }
    ]
  };
  selectedAlertTag.value = 'Coins';
}
onMounted(() => {
  emitter.$on('clear:alert', () => {
    clearAlertData();
  });
  handleAlertData();
});

//ALERT TAGS
const selectedAlertTag = ref(window.localStorage.getItem(constructLocalStorageName('selectedAlertTag')) || 'Coins');
const alertTags = ref(['Coins', 'Watchlist', 'Category', 'Subcategory']);
watch(selectedAlertTag, () => {
  window.localStorage.setItem(constructLocalStorageName('selectedAlertTag'), selectedAlertTag.value);
  alertData.value.coins = [];
  alertData.value.watchlist = { id: null };
  alertData.value.category = { id: null };
  alertData.value.subcategory = { id: null };
});

//PRESELECTED COINS
const coins = computed(() => store.getters.coins);
const preSelectedCoins = computed(() => {
  if (alertData.value.coins && coins.value) {
    return coins.value.filter(x => alertData.value.coins.includes(x.coin_uid)) || [];
  }
  return [];
});

//COINS
const remove = ref([
  '#',
  'coin',
  'fourchan_30d_avg_daily_posts',
  'fourchan_24h_posts',
  'youtube_30d_avg_daily_views',
  'youtube_24h_views',
  'tiktok_24h_plays',
  'tiktok_30d_avg_daily_plays',
  'pct_circulation_supply',
  'up_from_all_time_low',
  'all_time_low_price',
  'all_time_high_price',
  'down_from_all_time_high',
  'fourchain_30d_avg_daily_posts',
  'early_holder_balance_avalanche',
  'early_holder_balance_ethereum',
  'early_holder_balance_polygon',
  'early_holder_balance_bitcoin',
  'early_holder_balance_1d_avalanche',
  'early_holder_balance_1d_ethereum',
  'early_holder_balance_1d_polygon',
  'early_holder_balance_1d_bitcoin',
  'early_holder_balance_7d_avalanche',
  'early_holder_balance_7d_ethereum',
  'early_holder_balance_7d_polygon',
  'early_holder_balance_7d_bitcoin',
  'early_holder_balance_30d_avalanche',
  'early_holder_balance_30d_ethereum',
  'early_holder_balance_30d_polygon',
  'early_holder_balance_30d_bitcoin',
  'early_holder_supply_pct_avalanche',
  'early_holder_supply_pct_ethereum',
  'early_holder_supply_pct_polygon',
  'early_holder_supply_pct_bitcoin',
  'early_holder_supply_pct_1d_avalanche',
  'early_holder_supply_pct_1d_ethereum',
  'early_holder_supply_pct_1d_polygon',
  'early_holder_supply_pct_1d_bitcoin',
  'early_holder_supply_pct_7d_avalanche',
  'early_holder_supply_pct_7d_ethereum',
  'early_holder_supply_pct_7d_polygon',
  'early_holder_supply_pct_7d_bitcoin',
  'early_holder_supply_pct_30d_avalanche',
  'early_holder_supply_pct_30d_ethereum',
  'early_holder_supply_pct_30d_polygon',
  'early_holder_supply_pct_30d_bitcoin',
  'whale_balance_avalanche',
  'whale_balance_1d_avalanche',
  'whale_balance_7d_avalanche',
  'whale_balance_30d_avalanche',
  'whale_supply_pct_avalanche',
  'whale_supply_pct_1d_avalanche',
  'whale_supply_pct_7d_avalanche',
  'whale_supply_pct_30d_avalanche',
  'whale_balance_ethereum',
  'whale_balance_1d_ethereum',
  'whale_balance_7d_ethereum',
  'whale_balance_30d_ethereum',
  'whale_supply_pct_ethereum',
  'whale_supply_pct_1d_ethereum',
  'whale_supply_pct_7d_ethereum',
  'whale_supply_pct_30d_ethereum',
  'whale_balance_polygon',
  'whale_balance_1d_polygon',
  'whale_balance_7d_polygon',
  'whale_balance_30d_polygon',
  'whale_supply_pct_polygon',
  'whale_supply_pct_1d_polygon',
  'whale_supply_pct_7d_polygon',
  'whale_supply_pct_30d_polygon',
  'whale_balance_bitcoin',
  'whale_balance_1d_bitcoin',
  'whale_balance_7d_bitcoin',
  'whale_balance_30d_bitcoin',
  'whale_supply_pct_bitcoin',
  'whale_supply_pct_1d_bitcoin',
  'whale_supply_pct_7d_bitcoin',
  'whale_supply_pct_30d_bitcoin',
  'whale_balance_agg',
  'whale_balance_1d_agg',
  'whale_balance_7d_agg',
  'whale_balance_30d_agg',
  'whale_supply_pct_agg',
  'whale_supply_pct_1d_agg',
  'whale_supply_pct_7d_agg',
  'whale_supply_pct_30d_agg',
  'retail_balance',
  'retail_balance_1d',
  'retail_balance_7d',
  'retail_balance_30d',
  'retail_supply_pct',
  'retail_supply_pct_1d',
  'retail_supply_pct_7d',
  // 'commit_count',
  'circulating_market_cap',
  'fully_diluted_mcap',
  'num_dec_30d_avg_avalanche',
  'num_inc_24hr_avalanche',
  'num_dec_24hr_avalanche',
  'num_inc_24hr_ethereum',
  'num_inc_24hr_bitcoin',
  'num_dec_24hr_ethereum',
  'num_dec_24hr_bitcoin',
  'num_inc_30d_avg_ethereum',
  'num_inc_30d_avg_bitcoin',
  'num_inc_30d_avg_avalanche',
  'num_dec_30d_avg_ethereum',
  'num_dec_30d_avg_bitcoin',
  'num_inc_24hr_polygon',
  'num_dec_24hr_polygon',
  'num_inc_30d_avg_polygon',
  'num_dec_30d_avg_polygon',
  'num_inc_24hr_agg',
  'num_dec_24hr_agg',
  'num_inc_30d_avg_agg',
  'num_dec_30d_avg_agg',
  'early_holder_balance_tezos',
  'early_holder_balance_1d_tezos',
  'early_holder_balance_7d_tezos',
  'early_holder_balance_30d_tezos',
  'early_holder_supply_pct_tezos',
  'early_holder_supply_pct_1d_tezos',
  'early_holder_supply_pct_7d_tezos',
  'early_holder_supply_pct_30d_tezos',
  'whale_balance_tezos',
  'whale_balance_1d_tezos',
  'whale_balance_7d_tezos',
  'whale_balance_30d_tezos',
  'whale_supply_pct_tezos',
  'whale_supply_pct_1d_tezos',
  'whale_supply_pct_7d_tezos',
  'whale_supply_pct_30d_tezos',
  'num_inc_30d_avg_tezos',
  'num_dec_30d_avg_tezos',
  'num_inc_24hr_tezos',
  'num_dec_24hr_tezos',
  'early_holder_balance_hedera',
  'early_holder_balance_1d_hedera',
  'early_holder_balance_7d_hedera',
  'early_holder_balance_30d_hedera',
  'early_holder_supply_pct_hedera',
  'early_holder_supply_pct_1d_hedera',
  'early_holder_supply_pct_7d_hedera',
  'early_holder_supply_pct_30d_hedera',
  'whale_balance_hedera',
  'whale_balance_1d_hedera',
  'whale_balance_7d_hedera',
  'whale_balance_30d_hedera',
  'whale_supply_pct_hedera',
  'whale_supply_pct_1d_hedera',
  'whale_supply_pct_7d_hedera',
  'whale_supply_pct_30d_hedera',
  'num_inc_30d_avg_hedera',
  'num_dec_30d_avg_hedera',
  'num_inc_24hr_hedera',
  'num_dec_24hr_hedera',
  'fifty_two_week_high',
  'ytd_high',
  'down_from_52_week_high',
  'down_from_ytd_high',
  'token_serial',
  'combined_token_address',
  'unlocks_1d',
  'unlocks_market_cap_percent_1d',
  'unlocks_trading_vol_percent_1d',
  'unlocks_7d',
  'unlocks_market_cap_percent_7d',
  'unlocks_trading_vol_percent_7d',
  'unlocks_30d',
  'unlocks_market_cap_percent_30d',
  'unlocks_trading_vol_percent_30d',
  'unlocks_90d',
  'unlocks_market_cap_percent_90d',
  'unlocks_trading_vol_percent_90d',
  'unlocks_365d',
  'unlocks_market_cap_percent_365d',
  'unlocks_trading_vol_percent_365d'
]);
const ercMetrics = ref([
  '24h_inflow_ethereum',
  '24h_outflow_ethereum',
  '1h_inflow_ethereum',
  '1h_outflow_ethereum',
  '1h_exchange_netflows_ethereum',
  '24h_inflow_bitcoin',
  '24h_outflow_bitcoin',
  '1h_inflow_bitcoin',
  '1h_outflow_bitcoin',
  '1h_exchange_netflows_bitcoin',
  '24h_exchange_netflows_ethereFum',
  '24h_inflow_avalanche',
  '24h_outflow_avalanche',
  '1h_inflow_avalanche',
  '1h_outflow_avalanche',
  '1h_exchange_netflows_avalanche',
  '24h_exchange_netflows_avalanche',
  '24h_inflow_polygon',
  '24h_outflow_polygon',
  '1h_inflow_polygon',
  '1h_outflow_polygon',
  '1h_exchange_netflows_polygon',
  '24h_exchange_netflows_polygon',
  '24h_inflow_tezos',
  '24h_outflow_tezos',
  '1h_inflow_tezos',
  '1h_outflow_tezos',
  '1h_exchange_netflows_tezos',
  '24h_exchange_netflows_tezos',
  '24h_inflow_hedera',
  '24h_outflow_hedera',
  '1h_inflow_hedera',
  '1h_outflow_hedera',
  '1h_exchange_netflows_hedera',
  '24h_exchange_netflows_hedera',
  '24h_inflow_agg',
  '24h_outflow_agg',
  '1h_inflow_agg',
  '1h_outflow_agg',
  '1h_exchange_netflows_agg',
  '24h_exchange_netflows_agg'
]);
const coinDropdownOptions = ref([]);
const allCoins = ref([]);
const isDataReady = ref({ coins: false, coinDatapoints: false, watchlist: false, category: false });
const required = ref({ alertType: false });
const metricsEndPoints = computed(() => {
  const endPoints = {};
  for (const key in dataPoints.value) {
    dataPoints.value[key].forEach(x => {
      endPoints[x.id] = key;
    });
  }
  endPoints.live_price = 'market';
  return endPoints;
});
function editData() {
  if (props.alert?.id) {
    selectedAlertTag.value = Object.keys(props.alert.config)
      .map(x => titleize(x))
      .find(x => alertTags.value.includes(x));
    const tagsMapping = { sigdev: 'SigDev', tvl: 'On-Chain By Chain', tvl_aggregated: 'On-Chain Aggregated' };
    alertData.value.metrics = props.alert.config.metrics.map(x => {
      x.required = false;
      x.mainMetric = tagsMapping[metricsEndPoints.value[x.id]] || titleize(metricsEndPoints.value[x.id]);
      if (x.value.toString().includes('e')) x.value = fromExponential(x.value);
      return x;
    });
    nextTick(() => {
      if (props.alert.config.coins) {
        alertData.value.coins = props.alert.config.coins;
      }
      if (props.alert.config.watchlist) {
        alertData.value.watchlist.id = props.alert.config.watchlist.id;
      }
      if (props.alert.config.category) {
        alertData.value.category = props.alert.config.category;
      }
      if (props.alert.config.subcategory) {
        alertData.value.subcategory = props.alert.config.subcategory;
      }
    });
  }
}
watch(
  isDataReady,
  val => {
    if (Object.values(val).every(v => v == true)) {
      editData();
    }
  },
  { deep: true }
);
watch(
  () => props.alert,
  () => {
    if (Object.values(isDataReady.value).every(v => v == true)) {
      editData();
    }
  },
  { deep: true }
);
watch(
  coins,
  () => {
    const baseOptions = [{ label: 'Deselect All', id: 0, disabled: true }];
    if (coins.value) {
      allCoins.value = coins.value.map(coin => {
        return {
          id: coin?.uid,
          label: coin?.name,
          searchTerm: coin?.ticker
        };
      });
      coinDropdownOptions.value = baseOptions.concat(allCoins);
      isDataReady.value.coins = true;
    }
  },
  { immediate: true }
);
watch(
  () => alertData.value.coins,
  (newVal, oldVal) => {
    coinDropdownOptions.value[0].disabled = newVal.length == 0;
    if (newVal.includes(0)) {
      alertData.value.coins = [];
    }
  }
);

//METRIC TAGS
const metricTagsMap = {
  All: 'all',
  Market: 'market',
  Sentiment: 'sentiment',
  Development: 'development',
  Risk: 'risk',
  'On-Chain By Chain': 'tvl',
  'On-Chain Aggregated': 'tvl_aggregated',
  SigDev: 'sigdev',
  Derivatives: 'derivatives',
  Staking: 'staking'
};
const groupedMetrics = computed(() => {
  let metrics = coinDatapoints.value.length
    ? groupBy(
        coinDatapoints.value.filter(x => x.category != undefined && x.category != 'unlocks'),
        metrics => metrics.category
      )
    : null;
  //sort based on each category
  if (metrics) {
    metrics.all = coinDatapoints.value;
    if (metrics?.market) {
      metrics.market.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    if (metrics?.sentiment) {
      metrics.sentiment.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    if (metrics?.tvl) {
      metrics.tvl.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    if (metrics?.tvl_aggregated) {
      metrics.tvl_aggregated.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    if (metrics?.sigdev) {
      metrics.sigdev.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }
  return metrics;
});

const groupedMetricTags = computed(() => {
  const tagsMapping = { sigdev: 'SigDev', tvl: 'On-Chain By Chain', tvl_aggregated: 'On-Chain Aggregated' };
  return Object.keys(groupedMetrics.value)
    .sort((a, b) => (a > b ? 1 : -1))
    .map(t => {
      const val = tagsMapping[t] || titleize(t);
      return {
        label: val,
        action: val
      };
    });
});

//UPDATING METRIC
function filteredEndpoints(tag, selected_id) {
  const selectedMetrics = alertData.value.metrics.map(e => e.id);

  return groupedMetrics.value[metricTagsMap[tag]]
    .map(e => {
      return {
        id: e.id,
        label: e.name,
        text: e.name,
        type: e.type,
        category: e.category,
        disabled: selectedMetrics.includes(e.id) && e.id != selected_id
      };
    })
    .filter(metric => !removeMetrics.value?.includes(metric.id));
}
function updateTrig(trig, id) {
  const metric = filteredEndpoints(trig.mainMetric).filter(t => t.id == id)[0];
  return [metric.type, metric.label];
}

//ADDING METRIC
function addTrigger() {
  alertData.value.metrics.push({
    mainMetric: 'All',
    id: null,
    comparison: 'greater_than',
    value: null,
    type: null,
    required: false
  });
}

//WATCHLISTS
const allWatchlists = computed(() =>
  $watchlistStore.coins.map(list => ({
    id: list.id,
    label: list.name,
    text: list.name,
    coin_uids: list.entities_uids
  }))
);
const watchlistsReady = computed(() => $watchlistStore.watchlistLoader.coins);

watch(watchlistsReady, () => {
  if (watchlistsReady.value) isDataReady.value.watchlist = true;
});

onMounted(() => {
  isDataReady.value.watchlist = watchlistsReady.value;
});

watch(
  () => alertData.value.watchlist.id,
  () => {
    let watchlist = allWatchlists.value.find(w => w.id == alertData.value.watchlist.id);
    alertData.value.watchlist = watchlist ? { id: watchlist.id, name: watchlist.label } : { id: null };
  }
);

//CATEGORIES
const allCategories = ref([]);
const allSubcategories = ref([]);
async function fetchCategories() {
  const resp = (await $http.get('/data/coin_categories_subnav')).data;
  allCategories.value = (resp.categories || [])
    .filter(cat => cat.id !== 0)
    .map(cat => ({ id: cat.id, label: cat.title, text: cat.title }));

  (resp.categories || [])
    .filter(cat => cat.id !== 0)
    .forEach(cat => {
      let cat_coins = [];
      cat.subpages
        .filter(sub => sub.id !== 0)
        .forEach(sub => {
          allSubcategories.value.push({
            id: sub.id,
            html: `${sub.title}<span class='block text-xs text-gray-400'>${cat.title}</span>`,
            label: sub.title,
            coin_uids: sub.flat_coin_uids || []
          });
          cat_coins.push(sub.flat_coin_uids || []);
        });
      let category = allCategories.value.find(c => c.id == cat.id);
      category.coin_uids = uniqBy(cat_coins.flat(), 'coin_uid');
    });
  allSubcategories.value = allSubcategories.value.sort((a, b) => (a.label > b.label ? 1 : -1));
  isDataReady.value.category = true;
}
onMounted(() => {
  fetchCategories();
});

//COIN DATAPOINTS
const coinUIds = computed(() => {
  let coin_uids = null;
  if (selectedAlertTag.value == 'Coins') {
    coin_uids = props.alert?.id ? props.alert.config.coins : alertData.value.coins;
  } else if (selectedAlertTag.value == 'Category') {
    coin_uids = allCategories.value.find(
      c => c.id == (props.alert?.id ? props.alert.config.category.id : alertData.value.category?.id)
    )?.coin_uids;
  } else if (selectedAlertTag.value == 'Subcategory') {
    coin_uids = allSubcategories.value.find(
      c => c.id == (props.alert?.id ? props.alert.config.subcategory.id : alertData.value.subcategory?.id)
    )?.coin_uids;
  } else if (selectedAlertTag.value == 'Watchlist') {
    coin_uids = allWatchlists.value.find(
      w => w.id == (props.alert?.id ? props.alert.config.watchlist.id : alertData.value.watchlist?.id)
    )?.coin_uids;
  }
  return coin_uids;
});
const tvlMetricsAbsent = computed(() => {
  if (!groupedMetrics.value?.tvl || !groupedMetrics.value?.tvl_aggregated) return [];

  let tvlMetricsPresent = {};
  let tvlMetricIds = (groupedMetrics.value.tvl?.map(t => t.id) || []).concat(
    groupedMetrics.value.tvl_aggregated?.map(t => t.id) || []
  );
  let filteredCoinUIds = coins.value.filter(x => (coinUIds.value || []).includes(x.coin_uid));
  filteredCoinUIds.forEach(coin => {
    let coin_keys = Object.keys(coin);
    tvlMetricIds?.forEach(key => {
      if (coin_keys.includes(key)) {
        tvlMetricsPresent[key] = true;
      }
    });
  });
  tvlMetricsPresent = Object.keys(tvlMetricsPresent);
  return tvlMetricIds.filter(m => !tvlMetricsPresent.includes(m));
});

const removeMetrics = computed(() => {
  let metricsToIgnore = remove.value;

  if (!groupedMetrics.value?.tvl || !groupedMetrics.value?.tvl_aggregated) return metricsToIgnore;

  metricsToIgnore = metricsToIgnore.concat(tvlMetricsAbsent.value);
  if (coinUIds.value?.some(x => ercCategoryTokens.value.includes(x))) {
    return metricsToIgnore;
  }
  return [...new Set(metricsToIgnore.concat(ercMetrics.value))];
});
const ercCategoryTokens = computed(() => {
  let ercCategory = (store.state.categories.coin_sub_categories || []).find(c => c.name == 'ERC-20 Compatible Tokens');
  return ercCategory?.flat_coin_uids || [];
});
const coinDatapoints = computed(() => store.getters.coinDatapoints);
const dataPoints = computed(() => {
  const filteredDatapoints = JSON.parse(
    JSON.stringify(coinDatapoints.value.filter(datapoint => !removeMetrics.value?.includes(datapoint.id)))
  );
  let index = filteredDatapoints.findIndex(x => x.id == 'price');
  if (index > 0) {
    filteredDatapoints[index]['id'] = 'live_price';
    filteredDatapoints[index]['type'] = 'live_price';
  }
  return groupBy(filteredDatapoints, 'category');
});
watch(
  coinDatapoints,
  () => {
    if (coinDatapoints.value) {
      isDataReady.value.coinDatapoints = true;
    }
  },
  { immediate: true }
);
watch(removeMetrics, () => {
  if (removeMetrics.value.length > remove.value.length) {
    let metrics = alertData.value.metrics.filter(m => {
      return !removeMetrics.value.includes(m.id);
    });
    alertData.value.metrics = metrics.length
      ? metrics
      : [
          {
            mainMetric: 'All',
            id: null,
            name: null,
            comparison: 'greater_than',
            value: null,
            type: null,
            required: false
          }
        ];
  }
});

//REMOVING METRIC
function removeTrigger(index) {
  alertData.value.metrics.splice(index, 1);
}

//METRIC SYMBOL
const allMetrics = computed(() => {
  let values = Object.values(dataPoints.value).flat();
  let index = values.findIndex(x => x.id == 'price');
  if (index > 0) {
    values[index]['id'] = 'live_price';
    values[index]['type'] = 'live_price';
  }
  return values;
});
function selectedMetricType(metricId) {
  return get(
    find(allMetrics.value, metric => metric.id === metricId),
    'type'
  );
}
function getMetricSymbol(id) {
  if (['dollar', 'dollar_format', 'price', 'live_price'].includes(selectedMetricType(id))) {
    return '$';
  }
  if (['percent', 'simple_percent'].includes(selectedMetricType(id))) {
    return '%';
  }
  return '';
}

//VALIDATION
function showValidationError(data) {
  required.value.alertType = data.alertType;
  if (data.metrics && alertData.value.metrics) {
    data.metrics.forEach(i => {
      if (!alertData.value.metrics[i].id || !alertData.value.metrics[i].value)
        alertData.value.metrics[i].required = true;
    });
  }
  setTimeout(function () {
    required.value.alertType = false;
    if (data.metrics) {
      data.metrics.forEach(i => {
        alertData.value.metrics[i].required = false;
      });
    }
  }, 3000);
}
onMounted(() => {
  emitter.$on('CoinForm', data => {
    showValidationError(data);
  });
});

//COMPARISONS
const allComparisons = ref([
  { id: 'greater_than', text: '>' },
  { id: 'less_than', text: '<' }
]);
</script>
